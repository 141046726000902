// extracted by mini-css-extract-plugin
export var root = "PlasmicHeadlineButton-module--root--LFUOj";
export var h1__mLmRw = "PlasmicHeadlineButton-module--h1__mLmRw--7cXrp";
export var h1global_mode_dark__mLmRwjYvh = "PlasmicHeadlineButton-module--h1global_mode_dark__mLmRwjYvh--dr-xM";
export var slotTargetWord = "PlasmicHeadlineButton-module--slotTargetWord--ywVbD";
export var slotTargetWordglobal_mode_dark = "PlasmicHeadlineButton-module--slotTargetWordglobal_mode_dark--pAv80";
export var __wab_text = "PlasmicHeadlineButton-module--__wab_text--BuOnH";
export var __wab_slotStringWrapper = "PlasmicHeadlineButton-module--__wab_slot-string-wrapper--emheJ";
export var __wab_slot = "PlasmicHeadlineButton-module--__wab_slot--GfG3j";
export var h1__pd88E = "PlasmicHeadlineButton-module--h1__pd88E--anAyn";
export var slotTargetSymbol = "PlasmicHeadlineButton-module--slotTargetSymbol--K250U";
export var slotTargetSymbolglobal_mode_dark = "PlasmicHeadlineButton-module--slotTargetSymbolglobal_mode_dark--7J9QM";