// extracted by mini-css-extract-plugin
export var root = "PlasmicHomepage-module--root--piTGt";
export var rootglobal_mode_dark = "PlasmicHomepage-module--rootglobal_mode_dark--gDrQH";
export var rootglobal_arrangement_float = "PlasmicHomepage-module--rootglobal_arrangement_float--jSXK7";
export var _36Dot22 = "PlasmicHomepage-module--_36Dot22--6z12H";
export var callout = "PlasmicHomepage-module--callout--Jhb6H";
export var freeBox = "PlasmicHomepage-module--freeBox--kRCni";
export var svg__kiQg6 = "PlasmicHomepage-module--svg__kiQg6--T4OIL";
export var svg__iqt6V = "PlasmicHomepage-module--svg__iqt6V--c+cck";
export var text__ym5Bc = "PlasmicHomepage-module--text__ym5Bc--gnBAg";
export var svg__hdgd = "PlasmicHomepage-module--svg__hdgd--FrO5B";
export var likeness = "PlasmicHomepage-module--likeness--kjgie";
export var likenessglobal_arrangement_float = "PlasmicHomepage-module--likenessglobal_arrangement_float--T6f4U";
export var svg__gvVi7 = "PlasmicHomepage-module--svg__gvVi7--b1ybr";
export var words = "PlasmicHomepage-module--words--xbRgA";
export var wordsglobal_arrangement_float = "PlasmicHomepage-module--wordsglobal_arrangement_float--22vAC";
export var text__oP3Fs = "PlasmicHomepage-module--text__oP3Fs---TVyO";
export var svg__iok3E = "PlasmicHomepage-module--svg__iok3E--SoAeQ";
export var headline = "PlasmicHomepage-module--headline--JnSyO";
export var headlineglobal_arrangement_float = "PlasmicHomepage-module--headlineglobal_arrangement_float--WNEW2";
export var design = "PlasmicHomepage-module--design--FmwK2";
export var textglobal_highlight_work__p8PzPjCUoz = "PlasmicHomepage-module--textglobal_highlight_work__p8PzPjCUoz--NPE8W";
export var jeff = "PlasmicHomepage-module--jeff--JRnZO";
export var textglobal_highlight_about__r5O0RjOrod = "PlasmicHomepage-module--textglobal_highlight_about__r5O0RjOrod--97wRD";
export var ui = "PlasmicHomepage-module--ui--s8vUp";
export var uiglobal_arrangement_float = "PlasmicHomepage-module--uiglobal_arrangement_float--k5Sz3";
export var svg__laIzF = "PlasmicHomepage-module--svg__laIzF--kg8yO";
export var ux = "PlasmicHomepage-module--ux--Qdt7S";
export var uxglobal_arrangement_float = "PlasmicHomepage-module--uxglobal_arrangement_float--W+RYG";
export var text__raQdW = "PlasmicHomepage-module--text__raQdW--EtU0y";
export var svg__ff02 = "PlasmicHomepage-module--svg__ff02--GM5f-";
export var learnin = "PlasmicHomepage-module--learnin--aGch6";
export var learninglobal_highlight_about = "PlasmicHomepage-module--learninglobal_highlight_about--DjwgT";
export var learninglobal_highlight_work = "PlasmicHomepage-module--learninglobal_highlight_work--pjD1c";
export var learninglobal_arrangement_float = "PlasmicHomepage-module--learninglobal_arrangement_float--jrEiH";
export var svg__ofC2X = "PlasmicHomepage-module--svg__ofC2X--z5xmd";
export var service = "PlasmicHomepage-module--service--IVVgL";
export var serviceglobal_arrangement_float = "PlasmicHomepage-module--serviceglobal_arrangement_float--sGISD";
export var text__e0Mp7 = "PlasmicHomepage-module--text__e0Mp7--JfoaH";
export var svg__hgkau = "PlasmicHomepage-module--svg__hgkau--derWS";
export var tracks = "PlasmicHomepage-module--tracks--I94SR";
export var tracksglobal_arrangement_float = "PlasmicHomepage-module--tracksglobal_arrangement_float--hUKjN";
export var svg__xhEae = "PlasmicHomepage-module--svg__xhEae--1MBWO";
export var nav = "PlasmicHomepage-module--nav--FXAJg";
export var navglobal_arrangement_float = "PlasmicHomepage-module--navglobal_arrangement_float--Y2jdE";
export var reveal = "PlasmicHomepage-module--reveal--XQTzX";
export var svg__tyr6 = "PlasmicHomepage-module--svg__tyr6--czJdn";
export var home = "PlasmicHomepage-module--home--bY6JX";
export var svg___1A3Bw = "PlasmicHomepage-module--svg___1A3Bw--EM64l";
export var svgglobal_mode_dark___1A3BwJYvh = "PlasmicHomepage-module--svgglobal_mode_dark___1A3BwJYvh--aTbF4";
export var svg___9Ekg = "PlasmicHomepage-module--svg___9Ekg--ZCzw6";