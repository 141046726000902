// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react"
import { classNames } from "@plasmicapp/react-web"

export function MapIcon(props) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M12.574 4.913c-.579.631-1.214 1.273-1.964 1.85L9.39 5.177c.623-.479 1.16-1.017 1.696-1.601-.632-.581-1.274-1.22-1.847-1.968L10.826.392c.475.62 1.012 1.157 1.597 1.695.578-.63 1.215-1.27 1.967-1.85l1.22 1.586c-.626.48-1.164 1.019-1.7 1.602.635.581 1.279 1.22 1.851 1.967l-1.587 1.216c-.474-.618-1.012-1.155-1.6-1.695zM7.061 10.44c-.342.939-.265 1.769.247 2.471.332.456.67.674.965.774.3.101.62.102.943.017.71-.186 1.225-.718 1.344-1.044.213-.585.089-.908-.133-1.159-.288-.325-.853-.632-1.683-.835a8.547 8.547 0 00-1.683-.224zm-2.114.155c-.232 1.181-.05 2.403.745 3.494.546.75 1.209 1.243 1.939 1.49.724.245 1.45.227 2.094.057 1.227-.322 2.333-1.246 2.715-2.294.445-1.222.228-2.329-.515-3.168-.677-.765-1.7-1.207-2.706-1.453a9.608 9.608 0 00-.965-.182c.383-.431.837-.85 1.352-1.244l-1.212-1.59c-1.044.796-1.946 1.74-2.578 2.76-.992.074-1.967.276-2.732.626-.88.402-1.835 1.183-2.34 2.304-.53 1.177-.507 2.61.399 4.12l1.714-1.03c-.594-.99-.53-1.733-.289-2.27.267-.59.812-1.06 1.348-1.306a4.787 4.787 0 011.03-.314z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  )
}

export default MapIcon
/* prettier-ignore-end */
