// extracted by mini-css-extract-plugin
export var root = "PlasmicStripe-module--root--bJ9kr";
export var rootcolor_grey = "PlasmicStripe-module--rootcolor_grey--8US11";
export var rootlowlighted = "PlasmicStripe-module--rootlowlighted--tU-Cw";
export var rootcolor_orange = "PlasmicStripe-module--rootcolor_orange--uU0Ei";
export var rootcolor_blue = "PlasmicStripe-module--rootcolor_blue--NC+T2";
export var roothighlighted = "PlasmicStripe-module--roothighlighted--EiH--";
export var root___focusVisibleWithin = "PlasmicStripe-module--root___focusVisibleWithin--ptI+B";
export var title = "PlasmicStripe-module--title--Bk9i2";
export var titlecolor_grey = "PlasmicStripe-module--titlecolor_grey--5WPD+";
export var titlelowlighted = "PlasmicStripe-module--titlelowlighted--4ExYL";
export var titlecolor_red = "PlasmicStripe-module--titlecolor_red--Znku5";
export var titlecolor_orange = "PlasmicStripe-module--titlecolor_orange--sn6Vg";
export var titlecolor_yellow = "PlasmicStripe-module--titlecolor_yellow--AAXd1";
export var titlecolor_green = "PlasmicStripe-module--titlecolor_green--YTxHx";
export var titlecolor_mint = "PlasmicStripe-module--titlecolor_mint--bLTfB";
export var titlecolor_blue = "PlasmicStripe-module--titlecolor_blue--Cu8nK";
export var titlecolor_purple = "PlasmicStripe-module--titlecolor_purple--PHLn3";
export var title___focusVisibleWithin = "PlasmicStripe-module--title___focusVisibleWithin--2G1rb";
export var slotTargetLabel = "PlasmicStripe-module--slotTargetLabel--j7CQY";
export var slotTargetLabelcolor_grey = "PlasmicStripe-module--slotTargetLabelcolor_grey--3uumD";
export var slotTargetLabelcolor_orange = "PlasmicStripe-module--slotTargetLabelcolor_orange--1MxUY";
export var slotTargetLabelcolor_yellow = "PlasmicStripe-module--slotTargetLabelcolor_yellow--GkuRT";
export var slotTargetLabelcolor_green = "PlasmicStripe-module--slotTargetLabelcolor_green--VzVFa";
export var slotTargetLabelcolor_mint = "PlasmicStripe-module--slotTargetLabelcolor_mint--r5Fpo";
export var slotTargetLabelcolor_blue = "PlasmicStripe-module--slotTargetLabelcolor_blue--TqwF5";
export var __wab_slot = "PlasmicStripe-module--__wab_slot--sA5So";
export var slotTargetLabel___focusVisibleWithin = "PlasmicStripe-module--slotTargetLabel___focusVisibleWithin--Y9tT1";
export var slotTargetIcon = "PlasmicStripe-module--slotTargetIcon--kh1EI";
export var slotTargetIconcolor_grey = "PlasmicStripe-module--slotTargetIconcolor_grey--z7Si2";
export var slotTargetIconcolor_orange = "PlasmicStripe-module--slotTargetIconcolor_orange--FRrGL";
export var slotTargetIconcolor_yellow = "PlasmicStripe-module--slotTargetIconcolor_yellow--HdCc5";
export var slotTargetIconcolor_green = "PlasmicStripe-module--slotTargetIconcolor_green--jn51U";
export var slotTargetIconcolor_mint = "PlasmicStripe-module--slotTargetIconcolor_mint--s99Ub";
export var slotTargetIconcolor_blue = "PlasmicStripe-module--slotTargetIconcolor_blue--Aj2zt";
export var slotTargetIcon___focusVisibleWithin = "PlasmicStripe-module--slotTargetIcon___focusVisibleWithin--H7FZZ";
export var svg__rm0Cy = "PlasmicStripe-module--svg__rm0Cy--CmxF9";